<div class="myBase">
  <div class="myBackgroundLayer">
    <div class="row ECmyAccountHeader">
      <div class="d-flex justify-content-center ">
        <h2>
          <i class="fas fa-user fa-2x"></i>&nbsp;
          {{this.router.url.includes('EN')==true?'EC24 My Account': 'EC24 マイアカウント'}}<br>
          {{user.Myoji}}&nbsp;{{user.Shimei}}&nbsp;&nbsp;&nbsp;{{user.FirstName}}&nbsp;{{user.LastName}}
        </h2>
      </div>
    </div>
    <div *ngIf="!loaded">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="d-flex justify-content-center">
      <mat-accordion class="example-headers-align myPanel">

        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle
          [ngClass]="(user.FaithQuesCompletedDate !=null ) ? 'processCompleted':'processNotCompleted'">
          <mat-expansion-panel-header class="myExtendPanel">

            <div class="expansion-head1">
              {{this.router.url.includes('EN')==true?'Step 1': 'ステップ１'}}
            </div>
            <div class="expansion-head2">
              <i class="fas fa-user fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
              {{this.router.url.includes('EN')==true?'Personal Information': '個人情報'}}
            </div>
            <div class="expansion-head3" [ngClass]="user.FaithQuesCompletedDate!=null?'txtSuccess':'txtError'">
              {{this.router.url.includes('EN')==true?
              ((user.FaithQuesCompletedDate !=null) ?'Done':'Not done'):
              ((user.FaithQuesCompletedDate !=null) ?'完了':'未完了')
              }}

            </div>

              <div class="expansion-head4">
                <button mat-button (click)="onClickEditRegistrationPersonal()" class="btnRegistrantUpdate"
                  cdkFocusInitial>
                  {{this.router.url.includes('EN')==true?'Edit':'編集'}}
                </button>
              </div>


          </mat-expansion-panel-header>
          <div class="row">
            <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?'Registererd : ':'登録日 : '}}
              {{user.FaithQuesCompletedDate |
              date:"yyyy/MM/dd"}}
            </div>
          </div>
          <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">
              {{this.router.url.includes('EN')==true?'Next': '次へ'}}
            </button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle
          [ngClass]="conference.RegisteredDate !=null? 'processCompleted':'processNotCompleted'">
          <mat-expansion-panel-header class="myExtendPanel">

            <div class="expansion-head1">
              {{this.router.url.includes('EN')==true?'Step 2': 'ステップ２'}}
            </div>
            <div class="expansion-head2">
              <i class="fas fa-users fa-2x"></i>&nbsp;<br>
              {{this.router.url.includes('EN')==true?'Conference Information':
              'カンファレンス情報'}}
            </div>
            <div class="expansion-head3" [ngClass]="conference.RegisteredDate !=null? 'txtSuccess':'txtError'">
              {{this.router.url.includes('EN')==true?
              (conference.RegisteredDate !=null? 'Done':'Not done'):
              (conference.RegisteredDate !=null ? '完了':'未完了')
              }}

            </div>

            <div class="expansion-head4">
              <button mat-button (click)="onClickEditRegistrationConference()"
                [disabled]="this.user.FaithQuesCompletedDate==null" class="btnRegistrantUpdate" cdkFocusInitial>
                {{this.router.url.includes('EN')==true?
                (conference.RegisteredDate !=null ?'Edit':'Start'):
                (conference.RegisteredDate !=null?'編集':'スタート')
                }}
              </button>
            </div>

          </mat-expansion-panel-header>
          <div class="row">
            <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?'Registererd : ':'登録日 : '}}
              {{conference.RegisteredDate |
              date:"yyyy/MM/dd"}}
            </div>
          </div>
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">
              {{this.router.url.includes('EN')==true?'Back': '戻る'}}
            </button>
            <button mat-button color="primary" (click)="nextStep()">
              {{this.router.url.includes('EN')==true?'Next': '次へ'}}
            </button>
          </mat-action-row>
        </mat-expansion-panel>

        <div *ngIf="paymentProcessing" class="d-flex justify-content-center">
          <mat-progress-bar mode="indeterminate" class="myBar"></mat-progress-bar>
        </div>

        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle
          [ngClass]="(conference.ConferenceFeePaidDate !=null) ? 'processCompleted':'processNotCompleted'">
          <mat-expansion-panel-header class="myExtendPanel myPaymentPanel">
            <div class="expansion-head1">
              {{this.router.url.includes('EN')==true?'Step 3': 'ステップ３'}}
            </div>
            <div class="expansion-head2">
              <i class="fas fa-shopping-cart  fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
              {{this.router.url.includes('EN')==true?'Payment': '支払い'}}
            </div>
            <div class="expansion-head3"
              [ngClass]="(conference.ConferenceFeePaidDate !=null) ? 'txtSuccess':'txtError'">
              {{this.router.url.includes('EN')==true?
              ((conference.ConferenceFeePaidDate !=null) ?'Done':'Not done'):
              ((conference.ConferenceFeePaidDate !=null) ?'完了':'未完了')
              }}
            </div>

            <div class="expansion-head4">
              <!-- ###### A. Go to Authorize.net ###### -->
              <!-- <button class="AcceptUI btnRegistrantUpdateSub"
                    data-billingAddressOptions='{"show":false, "required":false}'
                    data-apiLoginID="2vp8ZSGt45k"
                    data-clientKey="4MTc2hmJ3j6aS6tYWy8F2yzftfbPVhnVc27ym2ZJ49ukJm7skDfA8sS9d3Qfc8eL"
                    data-acceptUIFormBtnTxt="Pay"
                    data-acceptUIFormHeaderTxt="Credit Card Information"
                    data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}'
                    data-responseHandler="responseHandlerECFee"
                    (click)="onClickEditRegistrationPayment()"
                    [disabled]="this.user.FaithQuesCompletedDate==null || this.conference.RegisteredDate==null"
                    >
                    {{this.router.url.includes('EN')==true?'Pay $80 for Application fee':'申込金80ドルを支払う'}}
                    </button> -->

              <!-- #######  B. Go to paypal #######-->
               <!-- <button mat-button [disabled]="conference.RegisteredDate ==null" (click)="onClickPaymentByPaypal()"
                class="btnRegistrantUpdateSub2" cdkFocusInitial>
                {{this.router.url.includes('EN')==true?'Pay $80':'申込金$80支払'}}
              </button> -->

            <!-- ###### C. Go to payment page ####### -->
              <button mat-button
                          [disabled]="conference.RegisteredDate ==null"
                          (click)="onClickProcessPayment()" class="btnRegistrantUpdateSub2" cdkFocusInitial>
                            {{this.router.url.includes('EN')==true?
                            (conference.ConferenceFeePaidDate !=null?'Completed':'Pay')
                            :
                            (conference.ConferenceFeePaidDate !=null?'支払い済':'支払いに進む')}}
                          </button>
            </div>
           <!-- ############# OLD removed ##############
             <mat-panel-title>
                        {{this.router.url.includes('EN')==true?'Step 3': 'ステップ３'}}
                    </mat-panel-title>
                    <mat-panel-title>
                        <i class="fas fa-shopping-cart  fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{this.router.url.includes('EN')==true?'Payment': '支払い'}}
                    </mat-panel-title>
                    <mat-panel-description
                        [ngClass]="(conference.ConferenceFeePaidDate !=null || conference.ConferenceFee==0 ) ? 'txtSuccess':'txtError'">
                        {{this.router.url.includes('EN')==true?
                        ((conference.ConferenceFeePaidDate !=null || conference.ConferenceFee==0 ) ?'completed':'Not completed'):
                        ((conference.ConferenceFeePaidDate !=null  || conference.ConferenceFee==0 ) ?'完了':'未完了')
                        }}
                        <button class="AcceptUI btnRegistrantUpdateSub"
                            data-billingAddressOptions='{"show":false, "required":false}'
                            data-apiLoginID="2vp8ZSGt45k"
                            data-clientKey="4MTc2hmJ3j6aS6tYWy8F2yzftfbPVhnVc27ym2ZJ49ukJm7skDfA8sS9d3Qfc8eL"
                            data-acceptUIFormBtnTxt="Pay"
                            data-acceptUIFormHeaderTxt="Payment Information"
                            data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}'
                            data-responseHandler="responseHandlerECFee"
                            (click)="onClickEditRegistrationPayment()"
                            [disabled]="this.user.RegisteredDate==null || this.conference.RegisteredDate==null"
                            >
                            {{this.router.url.includes('EN')==true?
                            (conference.RegisteredDate !=null ?'Edit':'Start'):
                            (conference.RegisteredDate !=null ?'編集':'スタート')
                            }}
                        </button>
                    </mat-panel-description>
                    ############# OLD removed ##############-->


          </mat-expansion-panel-header>
          <div class="row">
            <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?
              'If you register after November 14, you must pay in full. If for some reason you are unable to pay at this time, please contact us at ecregistration@equipper.org. If you cancel, we will refund the difference minus the $80 application fee. If you apply for a scholarship after November 4, we will contact you as soon as your scholarship is confirmed.'
              :
              '11月14日以降に申し込みをされる場合は、全額支払いとなっております。都合により現時点での支払いができない場合はecregistration@equipper.org までご連絡ください。
              キャンセルされる場合、申込金にあたる80ドルを差し引いた差額を返金いたします。
              奨学金を11月4日以降に申し込まれた方は、は奨学金が確定した段階でこちらより随時ご連絡をさせていただきますので連絡を受けてからお支払いをお願いいたします。'}}<br>
              {{this.router.url.includes('EN')==true?'Paid Date : ':'支払い日 : '}}
              {{conference.ConferenceFeePaidDate | date:"yyyy/MM/dd"}}
            </div>
            <!-- <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?'Fee : ':'参加費 : '}}
              {{conference.ConferenceFee
              | currency:'JPY':'symbol'}}
            </div> -->
          </div>
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">
              {{this.router.url.includes('EN')==true?'Back': '戻る'}}
            </button>
            <button mat-button color="primary" (click)="nextStep()">
              {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
            </button>
          </mat-action-row>
        </mat-expansion-panel>

      </mat-accordion>
    </div>


     <div class="d-flex justify-content-center">
      <div class="row myAccountSection">
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Your Small Group No.': 'あなたのスモールグループ番号'}}
            </div>
            <div class="itemDetail">
              {{subProfile.SmallGroupNo}}
            </div>
          </button>
        </div>
         <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Your Room No.': 'あなたの部屋番号'}}
            </div>
            <div class="itemDetail">
              {{subProfile.RoomNo}}
            </div>
          </button>
        </div>
         <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Your approved Service': 'あなたの承認された奉仕'}}
            </div>
            <span *ngFor="let myVolunteer of this.myApprovedVolunteers"  class="itemDetailSub">
              {{this.router.url.includes('EN')==true?
              myVolunteer.VolunteerName
              :
              myVolunteer.VolunteerName

            }}&nbsp;&nbsp;
            </span>
          </button>
        </div>
         <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Your approved Scholarship amount': 'あなたの承認された奨学金'}}
            </div>
            <div class="itemDetail">
              {{conference.ApprovedScholarshipAmount | currency}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleLg" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Your flight information(Arrival)': 'あなたのフライト情報（到着）'}}
            </div>
            <div class="row summaryTable">
               <div class="col-10 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Bus transportation to the venue?'
                   :
                   '会場までのバス必要？'}}:
                 {{this.router.url.includes('EN')==true?
                 (conference.NeedCommingBus==true?'Yes':'No')
                 :
                 (conference.NeedCommingBus==true?'はい':'いいえ')
               }}
                 </div>
               </div>

               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Bus Departure time & place'
                   :
                   'バス出発時刻＆場所'}}:
                   {{conference.CommingBusDeparturePlace}}<br>
                 </div>
               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Aireline'
                   :
                   '航空会社'}}:
                   {{conference.CommingAirline}}
                 </div>

               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Fright No.'
                   :
                   '便名'}}:
                      {{conference.CommingFlightNo}}
                 </div>

               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Departure City'
                   :
                   '航空名'}}:
                    {{conference.DepartureCity}}
                 </div>
               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Arrival Date&Time'
                   :
                   '到着日&時間'}}:
                   {{conference.ArrivalDateTime| date: 'MM/dd/yyyy hh:mm a'}}
                 </div>
               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Domestic or International'
                   :
                   '国際線・国内線'}}:
                   {{conference.CommingInternationalDomestic}}
                 </div>
               </div>
             </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleLg" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Your flight information(Departure)': 'あなたのフライト情報（出発）'}}
            </div>
            <div class="row summaryTable">
               <div class="col-10 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Bus transportation to Airport?'
                   :
                   '空港までのバス必要？'}}:
                 {{this.router.url.includes('EN')==true?
                 (conference.NeedReturningBus==true?'Yes':'No')
                 :
                 (conference.NeedReturningBus==true?'はい':'いいえ')
               }}
                 </div>
               </div>

               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Bus Departure time & place'
                   :
                   'バス出発時刻＆場所'}}:
                   {{conference.ReturningBusDeparturePlace}}<br>
                 </div>
               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Aireline'
                   :
                   '航空会社'}}:
                   {{conference.ReturningAirline}}
                 </div>

               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Fright No.'
                   :
                   '便名'}}:
                      {{conference.ReturningFlightNo}}
                 </div>

               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Destination City'
                   :
                   '航空名'}}:
                    {{conference.ReturningCity}}
                 </div>
               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Arrival Date&Time'
                   :
                   '出発日&時間'}}:
                   {{conference.DepartureDateTime| date: 'MM/dd/yyyy hh:mm a'}}
                 </div>
               </div>
               <div class="col-8 col-sm-5 col-md-5">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Domestic or International'
                   :
                   '国際線・国内線'}}:
                   {{conference.ReturningInternationalDomestic}}
                 </div>
               </div>
             </div>
          </button>
        </div>

        <!-- <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleMd" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Your participation fee': 'あなたの参加費用'}}
            </div>
            <div *ngIf="conference.ApplicationFeePaidDate" class="row summaryTable">
               <div class="col-10 col-sm-10 col-md-10">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Application fee'
                   :
                   '申込金'}} =
                 {{conference.ApplicationFee| currency}},
                 {{conference.ApplicationFeePaidDate | date: 'MM/dd/yyyy'}}&nbsp;&nbsp;
                 {{this.router.url.includes('EN')==true?
                 'Paid'
                 :
                 '済'}}
                 </div>
               </div>
             </div>
             <div *ngIf="conference.ConferenceFeePaidDate" class="row summaryTable">
              <div class="col-10 col-sm-10 col-md-10">
                <div class="summaryTitle">
                  {{this.router.url.includes('EN')==true?
                  'Remaining payment'
                  :
                  '残り支払い'}} =
                {{conference.ConferenceFeePaidAmount| currency}},
                {{conference.ConferenceFeePaidDate | date: 'MM/dd/yyyy'}}&nbsp;&nbsp;
                {{this.router.url.includes('EN')==true?
                'Paid'
                :
                '済'}}
                </div>
              </div>
            </div>
          </button>
        </div> -->
      </div>
    </div>

    <form
    [formGroup]="myFormGroup"
    (ngSubmit)="onSubmit()"
    >
    <div *ngIf="this.authService.isLoggedIn() && this.ecRegistrationService.checkLoginUserAccessEC()"
     class="d-flex justify-content-center">
     <div class="editSubProfile">
      <div class="editProfileTitle">
        EC Staff Only
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Small Group No.': 'スモールグループ番号'}}
            </mat-label>
            <input matInput placeholder="" formControlName="mySmallGroupNo"
              class="profileInputBox">
          </mat-form-field>
        </div>
        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Room No.': '部屋番号'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myRoomNo"
              class="profileInputBox">
          </mat-form-field>
        </div>
        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Discount $': '割引 $'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myDiscountAmount"
              class="profileInputBox">
          </mat-form-field>
        </div>

        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Application Fee $': 'アプリケーション費 $'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myApplicationFeeAmount"
              class="profileInputBox">
          </mat-form-field>
        </div>

<!--
        <div class="col">
              <mat-form-field appearance="outline" class="registrationInputForm">
                <mat-label>
                  {{this.router.url.includes('EN')!=true?'アプリケーション費を選んでください': 'Select the Application Fee'}}

                </mat-label>
                <mat-select  placeholder=""
                  formControlName="myPaymentOption" class="matInputBox" ngDefaultControl
                  (selectionChange)="onSelectPaymentAmount($event.value)">

                  <div>
                    <mat-option *ngFor="let paymentOption of paymentOptionJP" [value]="paymentOption.value" class="matInputBox">
                      {{ paymentOption.display }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field><br>

            </div>
-->

        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Application Fee Paid Date': 'アプリケーション費支払日'}}
            </mat-label>
            <input matInput placeholder="MM/dd/yyyy" formControlName="myApplicationFeePaidDate"
            (dateChange)="onChangeApplicationFeePaidDate($event)" [matDatepicker]="ApplicationFeePaidDate"
              class="profileInputBox">
              <mat-datepicker-toggle matSuffix [for]="ApplicationFeePaidDate"></mat-datepicker-toggle>
              <mat-datepicker #ApplicationFeePaidDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Conference Fee Paid Date': '参加費支払日'}}
            </mat-label>
            <input matInput placeholder="MM/dd/yyyy" formControlName="myPaidDate"
            (dateChange)="onChangePaidDate($event)" [matDatepicker]="PaidDate"
              class="profileInputBox">
              <mat-datepicker-toggle matSuffix [for]="PaidDate"></mat-datepicker-toggle>
              <mat-datepicker #PaidDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Approved Scholarship $': '承認された奨学金 $'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myApprovedScholarshipAmount"
              class="profileInputBox">
          </mat-form-field>
        </div>
        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditFormTextarea">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Memo': 'メモ'}}
            </mat-label>
            <textarea matInput placeholder="" formControlName="myMemo"
              class="profileInputTextarea"></textarea>
          </mat-form-field>
        </div>

       <div class="col">
          <mat-form-field class="profileEditForm" appearance="outline">
            <mat-label>希望した奉仕</mat-label>
            <mat-select name="SelectedVolunteer" formControlName="mySelectedServices" multiple
            [(ngModel)]="this.conference.myVolunteerSelections">
              <mat-option *ngFor="let myVolunteer of this.conference.myVolunteerSelections" [value]="myVolunteer"
              disabled>
              {{ myVolunteer.VolunteerName }}
            </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field class="profileEditForm" appearance="outline">
            <mat-label>承認された奉仕</mat-label>
            <mat-select name="ApprovedVolunteer" formControlName="myApprovedServices" multiple class="matInputBox">
              <mat-option #matOption *ngFor="let listVolunteer of listVolunteers" [value]="listVolunteer.value"
                class="matInputBox" (click)="onClickApprovedService(matOption)">
                {{ listVolunteer.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col" >
        <button  type="button"  class="btn btnProcess" (click)="onClickSaveSubProfile()">
          {{this.router.url.includes('EN')==true?'Save': '保存'}}
        </button>
      </div>
      </div>
     </div>

    </div>
    </form>
  </div>
</div>
