<div class="myBase">
  <div class="myBackgroundLayer">
    <div class="row ECmyAccountHeader">
      <div class="d-flex justify-content-center ">
        <h2>
          <i class="fas fa-user fa-2x"></i>&nbsp;
          {{this.router.url.includes('EN')==true?'EC22 My Account': 'GRC25 マイアカウント'}}<br>
          {{user.Myoji}}&nbsp;{{user.Shimei}}&nbsp;&nbsp;&nbsp;{{user.FirstName}}&nbsp;{{user.LastName}}
        </h2>
      </div>
    </div>
    <div *ngIf="!loaded">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="d-flex justify-content-center">
      <mat-accordion class="example-headers-align myPanel">

        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle
          [ngClass]="(user.FaithQuesCompletedDate !=null ) ? 'processCompleted':'processNotCompleted'">
          <mat-expansion-panel-header class="myExtendPanel">

            <div class="expansion-head1">
              {{this.router.url.includes('EN')==true?'Step 1': 'ステップ１'}}
            </div>
            <div class="expansion-head2">
              <i class="fas fa-user fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
              {{this.router.url.includes('EN')==true?'Personal Information': '個人情報'}}
            </div>
            <div class="expansion-head3" [ngClass]="user.FaithQuesCompletedDate!=null?'txtSuccess':'txtError'">
              {{this.router.url.includes('EN')==true?
              ((user.FaithQuesCompletedDate !=null) ?'Done':'Not done'):
              ((user.FaithQuesCompletedDate !=null) ?'完了':'未完了')
              }}

            </div>
            <div class="expansion-head4">
              <button mat-button (click)="onClickEditRegistrationPersonal()" class="btnRegistrantUpdate"
                cdkFocusInitial>
                {{this.router.url.includes('EN')==true?'Edit':'編集'}}
              </button>
            </div>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?'Registererd : ':'登録日 : '}}
              {{user.FaithQuesCompletedDate |
              date:"yyyy/MM/dd"}}
            </div>
          </div>
          <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">
              {{this.router.url.includes('EN')==true?'Next': '次へ'}}
            </button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle
          [ngClass]="conference.RegisteredDate !=null? 'processCompleted':'processNotCompleted'">
          <mat-expansion-panel-header class="myExtendPanel">

            <div class="expansion-head1">
              {{this.router.url.includes('EN')==true?'Step 2': 'ステップ２'}}
            </div>
            <div class="expansion-head2">
              <i class="fas fa-users fa-2x"></i>&nbsp;<br>
              {{this.router.url.includes('EN')==true?'Conference Information':
              'カンファレンス情報'}}
            </div>
            <div class="expansion-head3" [ngClass]="conference.RegisteredDate !=null? 'txtSuccess':'txtError'">
              {{this.router.url.includes('EN')==true?
              (conference.RegisteredDate !=null? 'Done':'Not done'):
              (conference.RegisteredDate !=null ? '完了':'未完了')
              }}

            </div>
            <div class="expansion-head4">
              <button mat-button (click)="onClickEditRegistrationConference()"
                [disabled]="this.user.FaithQuesCompletedDate==null" class="btnRegistrantUpdate" cdkFocusInitial>
                {{this.router.url.includes('EN')==true?
                (conference.RegisteredDate !=null ?'Edit':'Start'):
                (conference.RegisteredDate !=null?'編集':'スタート')
                }}
              </button>
            </div>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?'Registererd : ':'登録日 : '}}
              {{conference.RegisteredDate |
              date:"yyyy/MM/dd"}}
            </div>
          </div>
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">
              {{this.router.url.includes('EN')==true?'Back': '戻る'}}
            </button>
            <button mat-button color="primary" (click)="nextStep()">
              {{this.router.url.includes('EN')==true?'Next': '次へ'}}
            </button>
          </mat-action-row>
        </mat-expansion-panel>

        <div *ngIf="paymentProcessing" class="d-flex justify-content-center">
          <mat-progress-bar mode="indeterminate" class="myBar"></mat-progress-bar>
        </div>

        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle
          [ngClass]="(subProfile.RegistrationFeePaidDate!=null && subProfile.BalanceAmount<=0) ? 'processCompleted':'processNotCompleted'">
          <mat-expansion-panel-header class="myExtendPanel myPaymentPanel">
            <div class="expansion-head1">
              {{this.router.url.includes('EN')==true?'Step 3': 'ステップ３'}}
            </div>
            <div class="expansion-head2">
              <i class="fas fa-shopping-cart  fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
              {{this.router.url.includes('EN')==true?'Payment': '支払い'}}
            </div>
            <div class="expansion-head3"
              [ngClass]="(subProfile.RegistrationFeePaidDate!=null && subProfile.BalanceAmount<=0) ? 'txtSuccess':'txtError'">
              {{this.router.url.includes('EN')==true?
              ((subProfile.RegistrationFeePaidDate!=null && subProfile.BalanceAmount<=0) ?'Done':'Not done'):
              ((subProfile.RegistrationFeePaidDate!=null && subProfile.BalanceAmount<=0) ?'完了':'未完了')
              }}
            </div>

            <div class="expansion-head4">
              <!-- ###### A. Go to Authorize.net ###### -->
              <!-- <button class="AcceptUI btnRegistrantUpdateSub"
                    data-billingAddressOptions='{"show":false, "required":false}'
                    data-apiLoginID="2vp8ZSGt45k"
                    data-clientKey="4MTc2hmJ3j6aS6tYWy8F2yzftfbPVhnVc27ym2ZJ49ukJm7skDfA8sS9d3Qfc8eL"
                    data-acceptUIFormBtnTxt="Pay"
                    data-acceptUIFormHeaderTxt="Credit Card Information"
                    data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}'
                    data-responseHandler="responseHandlerEC22Fee"
                    (click)="onClickEditRegistrationPayment()"
                    [disabled]="this.user.FaithQuesCompletedDate==null || this.conference.RegisteredDate==null"
                    >
                    {{this.router.url.includes('EN')==true?'Pay $80 for Application fee':'申込金80ドルを支払う'}}
                    </button> -->

              <!-- #######  B. Go to paypal #######-->
              <!-- <button mat-button [disabled]="conference.RegisteredDate ==null" (click)="onClickPaymentByPaypal()"
                class="btnRegistrantUpdateSub2" cdkFocusInitial>
                {{this.router.url.includes('EN')==true?'Pay $80':'申込金$80支払'}}
              </button> -->

              <!-- ###### C. Go to payment page ####### -->
              <!-- <button mat-button
                          [disabled]="conference.RegisteredDate ==null"
                          class="btnRegistrantUpdateSub2" cdkFocusInitial>
                          <a href="https://fee.jcfn.org/" target="_blank"> {{this.router.url.includes('EN')==true?
                            (conference.ConferenceFeePaidDate !=null?'Completed':'Pay')
                            :
                            (conference.ConferenceFeePaidDate !=null?'支払い済':'支払いに進む')}}</a>

                          </button> -->
            </div>
            <!-- ############# OLD removed ##############
             <mat-panel-title>
                        {{this.router.url.includes('EN')==true?'Step 3': 'ステップ３'}}
                    </mat-panel-title>
                    <mat-panel-title>
                        <i class="fas fa-shopping-cart  fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{this.router.url.includes('EN')==true?'Payment': '支払い'}}
                    </mat-panel-title>
                    <mat-panel-description
                        [ngClass]="(conference.ConferenceFeePaidDate !=null || conference.ConferenceFee==0 ) ? 'txtSuccess':'txtError'">
                        {{this.router.url.includes('EN')==true?
                        ((conference.ConferenceFeePaidDate !=null || conference.ConferenceFee==0 ) ?'completed':'Not completed'):
                        ((conference.ConferenceFeePaidDate !=null  || conference.ConferenceFee==0 ) ?'完了':'未完了')
                        }}
                        <button class="AcceptUI btnRegistrantUpdateSub"
                            data-billingAddressOptions='{"show":false, "required":false}'
                            data-apiLoginID="2vp8ZSGt45k"
                            data-clientKey="4MTc2hmJ3j6aS6tYWy8F2yzftfbPVhnVc27ym2ZJ49ukJm7skDfA8sS9d3Qfc8eL"
                            data-acceptUIFormBtnTxt="Pay"
                            data-acceptUIFormHeaderTxt="Payment Information"
                            data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}'
                            data-responseHandler="responseHandlerEC22Fee"
                            (click)="onClickEditRegistrationPayment()"
                            [disabled]="this.user.RegisteredDate==null || this.conference.RegisteredDate==null"
                            >
                            {{this.router.url.includes('EN')==true?
                            (conference.RegisteredDate !=null ?'Edit':'Start'):
                            (conference.RegisteredDate !=null ?'編集':'スタート')
                            }}
                        </button>
                    </mat-panel-description>
                    ############# OLD removed ##############-->


          </mat-expansion-panel-header>
          <div class="row">
            <!-- <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?
              'Kindly note that your registration will become official only after we confirmed the payment of conference fee or registration fee (5,000 yen).'
              :
              '参加費もしくは申込金（5,000円）の入金の確認が出来た時点でお申し込み完了となります。'}}<br>
              {{this.router.url.includes('EN')==true?'Paid Date : ':'支払い日 : '}}
              {{conference.ConferenceFeePaidDate | date:"yyyy/MM/dd"}}
            </div> -->
            <!-- <div class="registrationStepDetail">
              {{this.router.url.includes('EN')==true?'Fee : ':'参加費 : '}}
              {{conference.ConferenceFee
              | currency:'JPY':'symbol'}}
            </div> -->
          </div>



          <label class="lblAdmissionFree2">
            <!-- <div *ngIf="this.router.url.includes('EN')!=true">
              料金<br>
              <i class="fas fa-circle"></i>&nbsp;早割料金（日本時間3月15日まで）<br>
              銀行振込：3,000円（振込手数料は各自ご負担ください。）<br>
              クレジットカード支払：3,200円（決済手数料込）<br>
              <br>
              <i class="fas fa-circle"></i>&nbsp;正規料金（日本時間4月15日まで）<br>
              銀行振込：4,000円（振込手数料は各自ご負担ください。）<br>
              クレジットカード支払：4,200円（決済手数料込）<br>
              <br>
              入金の確認ができた時点でお申込み完了となります。<br>
            </div> -->
            <!-- <div *ngIf="this.router.url.includes('EN')==true">
              Conference Fee<br>
              <i class="fas fa-circle"></i>&nbsp;Early Bird (By March 15th Japan Time)<br>
              Bank Transfer: 3,000 yen (Please pay the bank transfer fee by yourself.)<br>
              Credit Card Payment: 3,200 yen (Transaction fee included.)<br>
              <br>
              <i class="fas fa-circle"></i>&nbsp;Regular (By April 15th Japan Time)<br>
              Bank Transfer: 4,000 yen (Please pay the bank transfer fee by yourself.)<br>
              Credit Card Payment: 4,200 yen (Transaction fee included.)<br>
              <br>
              Kindly note that your registration will become official only after we’ve confirmed the payment.<br>
            </div> -->
            <br>
            <div>
              <div class="stepTitle">
                <label>
                  {{this.router.url.includes('EN')==true?
                  'Please make payment of your nonrefundable 5,000 yen deposit or full amount via credit card payment or'
                  :
                  'クレジットカード決済か銀行振込にて、申込金5,000円、もしくは参加費の全額をお振り込みください。'}}
                </label>
                <label>
                  {{this.router.url.includes('EN')==true?
                  ' bank transfer below. Your registration will be completed after your payment of deposit is confirmed.'
                  :
                  'オンライン登録と申込み金（5,000円）のご入金を確認した時点で、お申込みの完了になります。'}}
                </label>
                <label>
                  {{this.router.url.includes('EN')==true?
                  'If you have any questions about fees, please contact the office at info@globalreturnees.org.'
                  :
                  '料金についてご不明な点がありましたら、事務局info@globalreturnees.orgまでご連絡ください。'}}
                </label>
              </div>
              <br>
              <div class="stepTitle">
                <label>
                  <i class="fas fa-shopping-cart"></i>&nbsp; {{this.router.url.includes('EN')==true?'Payment
                  Option *:': '支払い方法 *：'}}
                </label>
              </div>
              <div class="registrationInputFormLg">
                <mat-radio-group [(ngModel)]="conference.PaymentOption" (change)="onChangePaymentOption($event)"
                  class="radioBtnMain" required>
                  <mat-radio-button value="CreditCard" [checked]="conference.PaymentOption=='CreditCard'"
                    class="radioBtnItemPayment">
                    {{this.router.url.includes('EN')==true?'Credit Card Payment': 'クレジットカード支払'}}
                  </mat-radio-button>
                  <mat-radio-button value="Bank" [checked]="conference.PaymentOption=='Bank'"
                    class="radioBtnItemPayment">
                    {{this.router.url.includes('EN')==true?'Bank Transfer': '銀行振込'}}</mat-radio-button>
                </mat-radio-group>
                <div class="invalidComment">

                </div>
              </div>
            </div>
            <div class="divPaymentOption">
              <div *ngIf="showCreditCardPayment">
<br>
                <div class="stepTitle">
                  <label>
                    {{this.router.url.includes('EN')==true?
                    'Please note that extra processing fee (4%+20yen) will be charged for credit card payments.'
                    :
                    'クレジットカード決済には別途手数料（4%+20円）がかかります。ご理解ください。'}}
                  </label>
                </div>

                <div *ngIf="router.url.includes('EN')==true">
                  <a href="https://fee.jcfn.org/" target="_blank">
                    <b class="cardMessage">
                      {{'Link to Credit Card Payment Screen'}}
                    </b>
                  </a>
                </div>
                <div *ngIf="router.url.includes('EN')!=true">
                  <a href="https://fee.jcfn.org/" target="_blank">
                    <b class="cardMessage">
                      {{'クレジットカード支払画面リンク'}}
                    </b>
                  </a>
                </div>

              </div>
              <br>
              <div *ngIf="showBankPayment">
                <div *ngIf="this.router.url.includes('EN')!=true">
                  振込先口座情報<br>
                  <br>
                  （ゆうちょ口座からの振込）<br>
                  口座名　GRC（ジーアールシー）<br>
                  記号　10190<br>
                  番号　54569891<br>
                  <br>
                  （他金融機関からの振込）<br>
                  口座名　GRC（ジーアールシー）<br>
                  銀行名　ゆうちょ銀行（金融機関コード9900）<br>
                  店名　〇一八 店（ゼロイチハチ店）<br>
                  店番　018<br>
                  預金種目　普通<br>
                  口座番号　5456989<br>
                  <br><br>

                  該当する参加費をお振込みください。<br>
                  【全参加料金】<br>
                  一般：40,000円（3月10日まで）、42,000円（3月11日以降）<br>
                  夫婦（1人分）：39,000円（3月10日まで）、41,000円（3月11日以降）<br>
                  小学生（こどもプログラム）：27,300円<br>
                  子ども（3-6才）（こどもプログラム有）：19,500円<br>
                  子ども（3-6才）（こどもプログラム無）：16,500円<br>
                  子ども（0-2才）（ベッド・食事無、チャイルドケア有）：3,000円<br>
                  子ども（0-2才）（ベッド・食事・チャイルドケア無）：無料<br>
                  <br>
                  【2泊3日参加料金】<br>
                  一般：29,000円（3月10日まで）、31,000円（3月11日以降）<br>
                  夫婦（1人分）：28,000円（3月10日まで）、30,000円（3月11日以降）<br>
                  小学生（こどもプログラム）：19,600円<br>
                  子ども（3-6才）（こどもプログラム有）：14,000円<br>
                  子ども（3-6才）（こどもプログラム無）：12,000円<br>
                  子ども（0-2才）（ベッド・食事無、チャイルドケア有）：2,000円<br>
                  子ども（0-2才）（ベッド・食事・チャイルドケア無）：無料<br>
                  <br>
                  【1泊2日参加料金】<br>
                  一般：18,000円（3月10日まで）、20,000円（3月11日以降）<br>
                  夫婦（1人分）：17,000円（3月10日まで）、19,000円（3月11日以降）<br>
                  小学生（こどもプログラム）：11,900円<br>
                  子ども（3-6才）（こどもプログラム有）：8,500円<br>
                  子ども（3-6才）（こどもプログラム無）：7,500円<br>
                  子ども（0-2才）（ベッド・食事無、チャイルドケア有）：1,000円<br>
                  子ども（0-2才）（ベッド・食事・チャイルドケア無）：無料<br>
                  <br>
                  ホームページからも料金をご確認いただけます。
                  <div class="cardMessage">
                    <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=255&Itemid=166&lang=ja"
                      target="_blank" style="color: blue;">
                      全参加料金：
                    </a>

                    <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=219&Itemid=170&lang=ja"
                      target="_blank"  style="color: blue;">
                      部分参加料金：
                    </a>
                  </div>

                  なお、日帰り参加の方の参加料金については、別途、事務局から連絡させていただきます。



                </div>
                <div *ngIf="this.router.url.includes('EN')==true">
                  Transfer between Yuucho bank<br>
                  Account Name: GRC<br>
                  Kigo: 10190<br>
                  Bango: 54569891<br>
                  <br>
                  Transfer from other bank<br>
                  Account Name: GRC<br>
                  Bank Name: Yuucho Bank<br>
                  Branch Name/No: 018<br>
                  Account Type: Regular<br>
                  Account Number: 5456989<br>

                <br>

                  <br>
                  【Full-time Participation fee】<br>
                  Adult: 40,000yen (until March 10th), 42,000yen (after March 11th)<br>
                  Married couple (per person): 39,000yen (until March 10th), 41,000yen (after March 11th)<br>
                  7-12 yrs (Kid’s program): 27,300yen<br>
                  3-6 yrs (with Kid’s program): 19,500yen<br>
                  3-6 yrs (without Kid’s program): 16,500yen<br>
                  0-2 yrs (with childcare, no bed & meals): 3,000yen<br>
                  0-2 yrs (without childcare, no bed & meals):	no charge<br>
                  <br>
                  【Partial Participation (2 nights 3 days) fee】	<br>
                  Adult: 29,000yen (until March 10th), 31,000yen (after March 11th)<br>
                  Married couple (per person): 28,000yen (until March 10th), 30,000yen (after March 11th)<br>
                  7-12 yrs (Kid’s program): 19,600yen<br>
                  3-6 yrs (with Kid’s program): 14,000yen<br>
                  3-6 yrs (without Kid’s program): 12,000yen<br>
                  0-2 yrs (with childcare, no bed & meals): 2,000yen<br>
                  0-2 yrs (without childcare, no bed & meals):	no charge<br>
                  <br>
                  【Partial Participation (1 night 2 days) fee】	<br>
                  Adult: 18,000yen (until March 10th), 20,000yen (after March 11th)<br>
                  Married couple (per person): 17,000yen (until March 10th), 19,000yen (after March 11th)<br>
                  7-12 yrs (Kid’s program): 11,900yen<br>
                  3-6 yrs (with Kid’s program): 8,500yen<br>
                  3-6 yrs (without Kid’s program): 7,500yen<br>
                  0-2 yrs (with childcare, no bed & meals): 1,000yen<br>
                  0-2 yrs (without childcare, no bed & meals):	no charge<br>
                  <br>
                  Rates are also available on our website.
                  <div class="cardMessage">
                    <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=258&Itemid=219&lang=en"
                      target="_blank"  style="color: blue;">
                      Full-time Participation fee：
                    </a>

                    <!-- <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=219&Itemid=170&lang=ja"
                      target="_blank">
                      Partial Participation fee：
                    </a> -->
                  </div>
                  <div>
                    The office will contact you separately regarding the participation fee for one-day participants.
                  </div>




                </div>


              </div>
            </div>

          </label>





          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">
              {{this.router.url.includes('EN')==true?'Back': '戻る'}}
            </button>
            <button mat-button color="primary" (click)="nextStep()">
              {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
            </button>
          </mat-action-row>
        </mat-expansion-panel>

      </mat-accordion>
    </div>


    <div class="d-flex justify-content-center">


      <div class="row myAccountSection">
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleCost">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Registration Fee': '参加費'}}
            </div>
            <div class="itemDetail">
              {{subProfile.RegistrationFee | currency:"JPY":"symbol"}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModulePay">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Application Paid Amount': '申込金支払'}}
            </div>
            <div class="itemDetail">
              {{subProfile.ApplicationPaidAmount | currency:"JPY":"symbol"}}<br>
              {{subProfile.ApplicationPaidDate | date}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Approved Scholarship': '承認された奨学金'}}
            </div>
            <div class="itemDetail">
              {{subProfile.ApprovedScholarshipAmount | currency:"JPY":"symbol"}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleCost">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Child Registration Fee': '子ども参加費'}}
            </div>
            <div class="itemDetail">
              {{subProfile.ChildRegistrationFee | currency:"JPY":"symbol"}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModulePay">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Registration Fee Paid Amount': '参加費支払'}}
            </div>
            <div class="itemDetail">
              {{subProfile.RegistrationFeePaidAmount | currency:"JPY":"symbol"}}<br>
              {{subProfile.RegistrationFeePaidDate | date}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Discount Amount': '割引'}}
            </div>
            <div class="itemDetail">
              {{subProfile.DiscountAmount | currency:"JPY":"symbol"}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleCost" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Bus Fee': 'バス代'}}
            </div>
            <div class="itemDetail">
              {{subProfile.BusFee | currency:"JPY":"symbol"}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">

        </div>

        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleCost" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Balance Amount': '残高'}}
            </div>
            <div class="itemDetail">
              {{subProfile.BalanceAmount | currency:"JPY":"symbol"}}
            </div>
          </button>
        </div>



        <!-- <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModuleMd" >
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Participation fee': '参加費用'}}
            </div>
            <div class="row summaryTable">
               <div class="col-10 col-sm-10 col-md-10">
                 <div class="summaryTitle">
                   {{this.router.url.includes('EN')==true?
                   'Application fee'
                   :
                   '申込金'}} =
                 {{conference.ApplicationFee| currency}},
                 {{conference.ApplicationFeePaidDate | date: 'MM/dd/yyyy'}}&nbsp;&nbsp;
                 {{this.router.url.includes('EN')==true?
                 'Paid'
                 :
                 '済'}}
                 </div>
               </div>
             </div>
             <div *ngIf="conference.ConferenceFeePaidDate" class="row summaryTable">
              <div class="col-10 col-sm-10 col-md-10">
                <div class="summaryTitle">
                  {{this.router.url.includes('EN')==true?
                  'Remaining payment'
                  :
                  '残り支払い'}} =
                {{conference.ConferenceFeePaidAmount| currency}},
                {{conference.ConferenceFeePaidDate | date: 'MM/dd/yyyy'}}&nbsp;&nbsp;
                {{this.router.url.includes('EN')==true?
                'Paid'
                :
                '済'}}
                </div>
              </div>
            </div>
          </button>
        </div> -->
      </div>
    </div>

    <form
    [formGroup]="myFormGroup"
    (ngSubmit)="onSubmit()"
    >
     <div *ngIf="this.authService.isLoggedIn() && this.grcRegistrationService.checkLoginUserAccessGRC()"
     class="d-flex justify-content-center">
     <div class="editSubProfile">
      <div class="editProfileTitle">
        GRC Staff Only
      </div>
      <div class="row">


        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Registration Fee JPY': '参加費 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myRegistrationFee"
              class="profileInputBox">
          </mat-form-field>
        </div>

        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Application Fee Paid Amount JPY': '申込金支払 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myApplicationPaidAmount"
              class="profileInputBox">
          </mat-form-field>
          <mat-form-field  class="profileEditFormDate">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Application paid Date': ' 申込支払日'}}
            </mat-label>
            <input matInput placeholder="MM/dd/yyyy" formControlName="myApplicationPaidDate"
              [matDatepicker]="ApplicationFeePaidDate" />
            <mat-datepicker-toggle matSuffix [for]="ApplicationFeePaidDate"></mat-datepicker-toggle>
            <mat-datepicker #ApplicationFeePaidDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Approved Scholarship Amount': '承認された奨学金 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myApprovedScholarshipAmount"
              class="profileInputBox">
          </mat-form-field>
        </div>



        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Child Registration Fee JPY': ' 子ども参加費 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myChildRegistrationFee"
              class="profileInputBox">
          </mat-form-field>
        </div>

        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Registration Fee Paid Amount JPY': ' 参加費支払い 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myRegistrationFeePaidAmount"
              class="profileInputBox">
          </mat-form-field>
            <mat-form-field  class="profileEditFormDate">
              <mat-label>
                {{this.router.url.includes('EN')==true?'': '参加費支払い日'}}
              </mat-label>
              <input matInput placeholder="MM/dd/yyyy" formControlName="myRegistrationFeePaidDate"
                [matDatepicker]="RegistrationFeePaidDate" />
              <mat-datepicker-toggle matSuffix [for]="RegistrationFeePaidDate"></mat-datepicker-toggle>
              <mat-datepicker #RegistrationFeePaidDate></mat-datepicker>
            </mat-form-field>
        </div>



        <div class="col">
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Discount JPY': '割引 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myDiscountAmount"
              class="profileInputBox">
          </mat-form-field>
        </div>

        <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Bus Fee JPY': 'バス代 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myBusFee"
              class="profileInputBox">
          </mat-form-field>
        </div>

        <div class="col tempspace1" >
        </div>

        <!-- <div class="col  profileEditForm2" >
          <span>test</span>
        </div> -->

        <!-- <div class="col" >
          <mat-form-field appearance="outline" class="profileEditForm">
            <mat-label>
              {{this.router.url.includes('EN')==true?'Balance Amount JPY': '残額 円'}}
            </mat-label>
            <input matInput placeholder="" formControlName="myBalanceAmount"
              class="profileInputBox">
          </mat-form-field>
        </div> -->


        <div class="col" >
        <button  type="button"  class="btn btnProcess" (click)="onClickSaveSubProfile()">
          {{this.router.url.includes('EN')==true?'Save': '保存'}}
        </button>
      </div>
      </div>
     </div>

    </div>
    </form>
  </div>
</div>
