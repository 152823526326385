import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { AuthService } from "../../../../services/auth/auth.service";
import { EcRegistrationService } from "../../../../services/conference/ec/ec-registration.service"
import { User } from "../../../../models/user";
//import { Conference } from "../../../models/conference";
import { UserService } from "../../../../services/user/user.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
// import { LoginMessageComponent } from '../../dialog/login-message/login-message.component';
// import { ParticipantEditPersonalComponent } from '../../dialog/participant-edit-personal/participant-edit-personal.component';
// import { ParticipantEditConferenceComponent } from '../../dialog/participant-edit-conference/participant-edit-conference.component';
// import { DeleteConfirmationComponent} from '../../dialog/delete-confirmation/delete-confirmation.component';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import { ECConference } from 'src/app/models/conference/ECConference';
import { UserEditOthersProfileComponent } from '../../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { PayByPaypalComponent } from 'src/app/components/dialog/pay-by-paypal/pay-by-paypal.component';
import { RegistrationPersonalEmbedComponent } from 'src/app/components/dialog/EC/registration-personal-embed/registration-personal-embed.component';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { RegistrationPaymentEmbedComponent } from 'src/app/components/dialog/EC/registration-payment-embed/registration-payment-embed.component';
import { SubProfile } from 'src/app/models/conference/subProfile';
import { CheckGroup } from 'src/app/models/checkGroup';
import { ColumnGroup, MemberListGridTemplate } from 'src/app/models/memberListGridTemplate';
import { element } from 'protractor';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

export interface DialogData {
  Id: string
}
interface MyList{
	display:string;
  value:string;
}
interface PaymentOption{
  display: string;
  value: number;
}
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  myFormGroup:FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  step = 0;
  mySelectedLanguage: string;
  loaded: boolean = true;
  paymentProcessing:boolean=false;
  searchingRegistrant: string;
  showCreditCardPayment:boolean=false;
  showBankPayment:boolean=false;
  deleting:boolean=false;
  strCommittee:string;
  firstFormGroup: FormGroup;
  isMinor:boolean=false;
  myOriginallySelectedVolunteers:VolunteerSelection[]=[];
  myApprovedVolunteers:VolunteerSelection[]=[];
  temporalBalanceAmount?:number;

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate: null,
    LastAccessedDate: null,
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others
    Volunteers:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDeparturePlace:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,
    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  columnGroup: ColumnGroup = {
    id: null,
    value: null,
  };
  columnGroups: ColumnGroup[] = [];

  memberListGridTemplate: MemberListGridTemplate = {
    MemberListGridTemplateId:null,
    SelectedColumns:this.columnGroups,
    TemplateName:null,
    UserName:null,
  };

  listVolunteers: CheckGroup[] =
  [
    { id: 0, value: 'Prayer' },
    { id: 1, value: 'Praise Team' },
    { id: 2, value: 'Bible Reading' },
    { id: 3, value: 'Skit' },
    { id: 4, value: 'PA' },
    { id: 5, value: 'Propresenter' },
    { id: 6, value: 'Video/Photo' },
    { id: 7, value: 'Translator' },
    { id: 8, value: 'Interpreter' },
    { id: 9, value: 'Usher' },
    { id: 10, value: 'Small Group Leader' },
    { id: 11, value: 'Workshop Helper' },
    { id: 12, value: 'Kids Program Helper' },
    { id: 13, value: 'Book Store' },
    { id: 14, value: 'Nursing' },
    { id: 15, value: 'Reception' },
    { id: 16, value: 'Counselor' },
    { id: 17, value: 'Sharing Testimony' }

  ]
  subProfile:SubProfile={
    UserId:null,
    Email:null,
    FirstName:null,
    LastName:null,
    SmallGroupNo:null,
    RoomNo:null
  }


  // paymentOptionJP: PaymentOption[] =
  // [
  //   {
  //     "display": "アプリケーション費: 80ドル",
  //     "value": 80
  //   },
  //   {
  //     "display": "",
  //     "value":null
  //   },
  // ]

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder:FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    public ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) { }

  myApprovedOptions: string[] = [];
  mySearch: any = {};
  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.myFormGroup = this._formBuilder.group({
      mySmallGroupNo: [''],
      myRoomNo: [''],
      myDiscountAmount: [''],
      myApplicationFeeAmount:[''],
      myApplicationFeePaidDate: [''],
      myPaidDate: [''],
      myApprovedScholarshipAmount: [''],
      myMemo:[''],
      mySelectedServices:[''],
      myApprovedServices: [''],
    });

    if (this.router.url.includes('/conference/EC/myAccount') == true) {
      /************************************************************************
       * This means user came to  his/her own my account page.
       * So session 'myECSearchingUser' used for admin purpose must be deleted
       * **********************************************************************/
      sessionStorage.removeItem("myECSearchingUserId");
    }
    /*#####################################################################
      # Admin Work search for registrant
      ######################################################################*/
    if (sessionStorage.getItem("myECSearchingUserId") != undefined) {
      var searchingRegistrantId = sessionStorage.getItem("myECSearchingUserId")
      console.log("Use myECSearchingUserId for admin purpose: " + searchingRegistrantId)
      this.loaded = false;
      /*****************************************
       *  A) User info for searching registrant
       ****************************************/
      this.userService.getUserProfileById(searchingRegistrantId).subscribe((result: User) => {
        this.user = result;
        console.log('use received for admin purpose')
        /***********************************************
         *  B) Conference info for searching registrant
         ***********************************************/
        this.ecRegistrationService.getConferenceByUserId(searchingRegistrantId).subscribe(result => {
          if (result != null) {
            this.loaded = false;
            this.conference = result;
            this.loaded = true;
            console.log('conference received for admin purpose')
            this.setMyCommittee();
          } else {
            this.loaded = true;
          }
          /***********************************************
           *  C) SubProfile info for searching registrant
           ***********************************************/
          this.ecRegistrationService.getSubProfileByUserId(searchingRegistrantId).subscribe(result => {
            if (result != null) {
              console.log('sub profile received for admin purpose')
              this.loaded = false;
              this.subProfile = result;
              this.patchModelValue(this.subProfile, this.conference);
              this.loaded = true;
            } else {
              console.log('no sub profile exist yet')
              this.patchModelValue(this.subProfile, this.conference);
              this.loaded = true;
            }
          });
        });
      });
      this.loaded = true;
    } else {
      if (sessionStorage.getItem("loginUserName") != null) {
        console.log("Use login UserName for my as user")
        var loginUser = sessionStorage.getItem("loginUserName")
        this.loaded = false;
        /******************************
         *  A) User Info for login user
         ******************************/
        this.userService.getUserProfile(loginUser).subscribe(result => {
          this.user = result;
          if (this.user != null) {
            console.log('user found');
            sessionStorage.setItem("loginUserFirstName", this.user.FirstName);

            /************************************
            *  B) Conference Info for login user
            ************************************/
            //console.log('going to take conference info')
            this.ecRegistrationService.getConferenceByUserId(this.user.Id).subscribe(result => {
              if (result != null) {
                this.loaded = false;
                this.conference = result;
                sessionStorage.setItem("loginUserAccessEC", this.conference.ECCommitteeAccess == true ? "true" : "false");
                this.setMyCommittee();
                this.loaded = true;
              } else {
                this.loaded = true;
              }
              this.setConferenceInfoInSessionStorage();

              /***********************************************
              *  C) SubProfile info for searching registrant
              ***********************************************/
              this.ecRegistrationService.getSubProfileByUserId(this.user.Id).subscribe(result => {
                if (result != null) {
                  this.loaded = false;
                  this.subProfile = result;
                  this.patchModelValue(this.subProfile, this.conference);
                  this.loaded = true;
                } else {
                  console.log('no sub profile exist yet')
                  this.patchModelValue(this.subProfile, this.conference);
                  this.loaded = true;
                }
              });
            },
              error => {
                console.log('Oops, API error', error);
                this.loaded = true;
              }
            );
          }else{
            console.log('first time user');
            this.conference.UserId=this.user.Id;
          }
          this.loaded = true;
        },
          error => {
            console.log('Oops, API error', error);
            this.loaded = true;
          }
        );
      } else {
        this.loaded = true;
      }
    }
    this.loaded = true;
  }

  onSubmit() {
  }

  patchModelValue(subProfile:SubProfile, conference:ECConference){
    this.myApprovedVolunteers =           conference.myVolunteerSelections!=null?conference.myVolunteerSelections.filter(x=>x.VolunteerAccepted==true):[];
    this.myApprovedVolunteers.forEach(element => {
      this.myApprovedOptions.push(element.VolunteerName);
    });

    this.myFormGroup = this._formBuilder.group({
      mySmallGroupNo: [''],
      myRoomNo: [''],
      myDiscountAmount: [''],
      myApplicationFeeAmount:[''],
      myApplicationFeePaidDate:[''],
      myPaidDate: [''],
      myApprovedScholarshipAmount: [''],
      myMemo:[''],
      mySelectedServices:[''],
      myApprovedServices: [this.myApprovedOptions,],
    })

    this.myFormGroup.patchValue({
      mySmallGroupNo: subProfile.SmallGroupNo,
      myRoomNo:subProfile.RoomNo,
      myDiscountAmount:conference.ConferenceDiscountAmount,
      myApplicationFeeAmount:conference.ApplicationFee,
      myApplicationFeePaidDate:conference.ApplicationFeePaidDate,
      myPaidDate:conference.ConferenceFeePaidDate,
      myApprovedScholarshipAmount: conference.ApprovedScholarshipAmount,
      myMemo:conference.Memo,
    });
  }

  setConferenceInfoInSessionStorage(){
    sessionStorage.removeItem("myConferenceInfo");
    this.conference.UserId=this.user.Id;
    this.conference.FirstName=this.user.FirstName;
    this.conference.LastName=this.user.LastName;
    this.conference.Email=this.user.Email;
    sessionStorage.setItem("myConferenceInfo",
    JSON.stringify({ myConferenceSearch: this.conference })
    );
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  onClickEditRegistrationPayment() {

  sessionStorage.setItem("ECFeePreventCallingFunctionMultipleTimes", "mySession");
  /*************************************************************************
  According to Authorize.net, there is the following chainedInstruction.
  "IMPORTANT: When using the payment information form, be sure that your page
  loads the library after the button is defined."
  https://developer.authorize.net/api/reference/features/acceptjs.html
  To follow above instruction, I found it is important to import Authorize.net
  javascript program after this page is all loaded. Below line is to import
  javascript library into Angular component. First, I inserted this line in the
  ngOnInit, but is was not enough. The safest way is to add event to import the
  javascript library.
  Below line enable to open popup window to enter card information created by
  Authorize.net.
* **************************************************************************** */
  //this.loadScript('https://jstest.authorize.net/v3/AcceptUI.js');
  //this.loadScript('https://js.authorize.net/v3/AcceptUI.js');

  //this.conference.ApplicationFee=80;
  sessionStorage.setItem("myConferenceInfo",
  JSON.stringify({ myConferenceSearch: this.conference })
  );
  this.paymentProcessing =true;
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }
  setMyCommittee(){
  }

  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

  onClickEditRegistrationPersonal() {
    sessionStorage.removeItem("myWorkingOnPersonal"); // This is session being used during personal registration
    let navigationExtras: NavigationExtras = {
      queryParams:this.user
    };
    if(this.router.url.includes('conference/EC/myAccount')){
      this.router.navigate(["/registration/ec/basic/" +this.getMyLanguage()]);
    }else{
      this.openRegistrationPersonalEmbed();
    }
  }
  openRegistrationPersonalEmbed(){
    console.log('modifying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    //dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(RegistrationPersonalEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  getUpdatedUserById(Id:string){
    this.loaded=false;
    if(this.user!=null){
      this.userService.getUserProfileById(this.user.Id).subscribe(result => {
        this.user = result;
        this.loaded=true;
      });
    }else{
      this.loaded=true;
    }
  }

  onClickEditRegistrationConference() {
    sessionStorage.removeItem("myWorkingOnConference"); // This is session being used during personal registration
    let navigationExtras: NavigationExtras = {
      queryParams:this.user
    };
    if(this.router.url.includes('conference/EC/myAccount')){
      this.router.navigate(["/registration/ec/programType/" +this.getMyLanguage()]);
    }else{
      this.openRegistrationConferenceEmbed();
    }
  }
  openRegistrationConferenceEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'programType' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  onClickProcessPayment(){
    let navigationExtras: NavigationExtras = {
      queryParams:this.user
    };
    if(this.router.url.includes('conference/EC/myAccount')){
      this.router.navigate(["/registration/ec/conferenceFeeDetail/" +this.getMyLanguage()]);
    }else{
      this.openRegistrationPaymentEmbed();
    }
  }
  openRegistrationPaymentEmbed(){
    console.log('modifying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(RegistrationPaymentEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  onChangePaymentOption($event: MatRadioChange) {
   }

  onConfirmDelete() {

  }

  DeleteRegistrantById() {
  }

   onChangeCommittee($event:MatRadioChange){
  }

  getFormValue() {
    this.subProfile.SmallGroupNo = this.myFormGroup.get('mySmallGroupNo').value;
    this.subProfile.RoomNo =  this.myFormGroup.get('myRoomNo').value;
    this.subProfile.UserId = this.conference.UserId;
    this.subProfile.FirstName = this.conference.FirstName;
    this.subProfile.LastName = this.conference.LastName;
    this.subProfile.Email = this.conference.Email;

    this.conference.ConferenceDiscountAmount = parseInt(this.myFormGroup.get('myDiscountAmount').value);
    this.conference.ApplicationFee = parseInt(this.myFormGroup.get('myApplicationFeeAmount').value);
    this.conference.ApplicationFeePaidDate = this.myFormGroup.get('myApplicationFeePaidDate').value;
    this.conference.ConferenceFeePaidDate = this.myFormGroup.get('myPaidDate').value;
    this.conference.ApprovedScholarshipAmount = parseInt(this.myFormGroup.get('myApprovedScholarshipAmount').value);
    this.conference.Memo =this.myFormGroup.get('myMemo').value;
    // Should not use below
    //this.conference.myVolunteerSelections = this.myFormGroup.get('myApprovedServices').value;
 }

 onClickSaveSubProfile(){
  this.getFormValue();
  this.loaded = false;
  this.conference.ConferenceYear=2024;
  // 1. Save Sub Profile Info
  this.ecRegistrationService.upsertSubProfile(this.subProfile).subscribe(
    data => {
      // 2. Save Conference Info
      this.ecRegistrationService.upsertConference(this.conference).subscribe(
        data => {
        // 3.  Payment Date update
        this.ecRegistrationService.updateConferenceFeePaidDate(this.conference).subscribe(
          data => {
            // 4.  Application Fee
            if(this.conference.ApplicationFee==null && this.conference.ApplicationFee==0){
              this.conference.ApplicationFeePaidDate=null;
            }else if(this.conference.ApplicationFee!=null && this.conference.ApplicationFeePaidDate == null){
              this.conference.ApplicationFeePaidDate = new Date();
            }
            this.ecRegistrationService.updateApplicationFeePaidDate(this.conference).subscribe(
              data => {
                this.myMessage = this.getMyLanguage()=="EN"?"Sub Profile info saved successfully.":"情報が保存されました。";
                this.openSnackBar('success', 'close');
                this.ngOnInit();
                // 3. Save Volunteer Info
                this.createdApprovedVolunteerInstance();
                this.ecRegistrationService.approveVolunteers(this.conference).subscribe(
                  data => {
                    this.myMessage = this.getMyLanguage()=="EN"?"Sub Profile info saved successfully.":"情報が保存されました。";
                    this.openSnackBar('success', 'close');
                    this.loaded = true;
                    this.ngOnInit();
                    },
                    error=>{this.loaded = true;}
                    )
              },
            error=>{this.loaded = true;}
            )
          },
        error=>{this.loaded = true;}
        )
        },
        error=>{this.loaded = true;}
        )
    },
    error => {
      this.myMessage = this.getMyLanguage()=="EN"?"failed in saving Sub profile data.":"問題が生じ保存できませんでした。";
      this.openSnackBar('error', 'close');
      this.loaded = true;
    },
  )
}

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickPaymentByPaypal() {
    console.log('paying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='450px';//Desktoop
   dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(PayByPaypalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
      //Retreive updated registration Info.
      this.ngOnInit();
    })
  }

  onChangeApplicationFeePaidDate(event: MatDatepickerInputEvent<Date>) {
    console.log(event.value)
  }

  onChangePaidDate(event: MatDatepickerInputEvent<Date>) {
    console.log(event.value)
    this.temporalBalanceAmount = this.conference.ConferenceFee;
    console.log('0. Conference Fee: '+this.conference.ConferenceFee);
    if(this.conference.ApplicationFee!=null && this.conference.ApplicationFeePaidDate!=null){
      this.temporalBalanceAmount = this.temporalBalanceAmount-this.conference.ApplicationFee;
      //Application Feeはディスカウントや奨学金とは違ってConferecne Feeとは別枠で管理。

      console.log('1. Application Fee: '+this.conference.ApplicationFee);
    }
    if(this.conference.ConferenceDiscountAmount!=null){
      this.temporalBalanceAmount = this.temporalBalanceAmount-this.conference.ConferenceDiscountAmount;
      //this.conference.ConferenceFee = this.conference.ConferenceFee-this.conference.ConferenceDiscountAmount; ....removed 12/06/24
    }
    console.log('2. Conference Fee Discount Amount: '+this.conference.ConferenceDiscountAmount);
    console.log('3. Temporal Balance Amount: '+this.temporalBalanceAmount);
    if(this.conference.ApprovedScholarshipAmount!=null){
      this.temporalBalanceAmount = this.temporalBalanceAmount-this.conference.ApprovedScholarshipAmount;
      //this.conference.ConferenceFee= this.conference.ConferenceFee-this.conference.ApprovedScholarshipAmount; ....removed 12/06/24
      console.log('pay---pay---pay--pay');
      console.log('4. Scolarship: '+this.conference.ApprovedScholarshipAmount);
      console.log('5. Tempral Balance Amount: '+this.temporalBalanceAmount);
      console.log('6. Conference Fee: '+this.conference.ConferenceFee);
    }
    this.conference.ConferenceFeePaidAmount = this.temporalBalanceAmount;
    console.log("7. Conference Fee Paid Amount: "+this.temporalBalanceAmount);
  }

  onClickApprovedService(data: any) {
    if(data!=null && data.selected==true){
      this.myApprovedOptions.push(data.value);
    }else if(data!=null && data.selected==false){
      this.myApprovedOptions = this.myApprovedOptions.filter(function(ele){
        return ele != data.value;
      });
    }
  }

  createdApprovedVolunteerInstance(){
    var myFinalApprovals:VolunteerSelection[]=[];
      console.log('The number of volunteer to be saved: '+this.myApprovedOptions.length);
      this.myApprovedOptions.forEach(element=>{
        var myFinalApprove:VolunteerSelection={
          Id:null,
          UserId:null,
          Email:null,
          VolunteerName:null,
          VolunteerAccepted:null,
          AcceptedDate:null
        }
        myFinalApprove.Id=1;
        myFinalApprove.UserId = this.conference.UserId;
        myFinalApprove.Email = this.conference.Email;
        myFinalApprove.VolunteerName =element;
        myFinalApprove.VolunteerAccepted=true;
        myFinalApprove.AcceptedDate= new Date;
        myFinalApprovals.push(myFinalApprove);
      })
      this.conference.myVolunteerSelections = myFinalApprovals;
      console.log('Saved in model like this:')
      this.conference.myVolunteerSelections.forEach(element=>{
        console.log(element.VolunteerName);
      })
    //Need to initialize before going next
    this.myApprovedOptions = [];
  }

  // onSelectPaymentAmount(model: string){
  //   try{
  //     var paymentAmount= parseInt(model);
  //     if(paymentAmount==80){
  //       this.conference.ApplicationFee=paymentAmount;
  //       console.log('pay $80');
  //     }
  //   }catch(e){}

  //   if(model!=null ){}

  // }
}
