<div class="myBody myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
      <!-- <img src='../../../../../assets/images/GRC23.png' class="grcLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">4/4</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Personal Information':
              '個人情報'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'* ... Required': '* ... 必須項目'}}
              </i>
            </h2>



            <div>
              <div class="stepTitle2nd">
                <label>
                  {{this.router.url.includes('EN')==true?
                  'Are you a Christian ? *':
                  'クリスチャンですか？ *'}}
                </label>
              </div>
              <div class="registrationInputFormLg">
                <mat-radio-group formControlName="myChristian" (change)="onChangeChristian($event)"
                  class="radioBtnMain">
                  <mat-radio-button class="radioBtnItem" value=true [checked]="user.Christian==true">
                    {{this.router.url.includes('EN')==true?'Yes': 'はい'}}
                  </mat-radio-button>
                  <mat-radio-button class="radioBtnItem" value=false [checked]="user.Christian==false">
                    {{this.router.url.includes('EN')==true?'No': 'いいえ'}}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error
                  *ngIf="myFormGroup.get('myChristian').hasError('required')  && myFormGroup.get('myChristian').touched">
                  {{this.router.url.includes('EN')==true?'Not selected': '未入力です'}}
                </mat-error>
              </div>
            </div>
            <br>

            <div>
              <div class="stepTitle2nd">
                <label>
                  {{this.router.url.includes('EN')==true?
                  'Are you baptized ? *':
                  '洗礼を受けていますか？ *'}}
                </label>
              </div>
              <div class="registrationInputFormLg">
                <mat-radio-group formControlName="myBaptized" (change)="onChangeBaptized($event)" class="radioBtnMain">
                  <mat-radio-button class="radioBtnItem" value=true [checked]="user.Baptized==true">
                    {{this.router.url.includes('EN')==true?'Yes': 'はい'}}
                  </mat-radio-button>
                  <mat-radio-button class="radioBtnItem" value=false [checked]="user.Baptized==false">
                    {{this.router.url.includes('EN')==true?'No': 'いいえ'}}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error
                  *ngIf="myFormGroup.get('myBaptized').hasError('required')  && myFormGroup.get('myBaptized').touched">
                  {{this.router.url.includes('EN')==true?'Not selected': '未入力です'}}
                </mat-error>
              </div>
            </div>
            <br>
            <div>
              <div class="stepTitle2nd">
                <label>
                  <label>
                    {{this.router.url.includes('EN')==true? 'Your overseas experiences : *':'あなたの帰国情報：*'}}
                  </label>
                </label>
              </div>
              <div class="stepTitle3rd">
                <mat-radio-group formControlName="myReturneeInfo" [(ngModel)]="user.ReturneeInfo" class="radioBtnMain">

                  <div>
                    <div class="registrationInputFormLg">
                      <div class="regionGroupReturnee">
                        {{this.router.url.includes('EN')==true?'I have returned to Japan: ': '帰国しました：'}}
                      </div>
                      <div>
                        <mat-radio-button value='I have return in 2025' [checked]="user.ReturneeInfo=='I have return in 2025'"
                          class="radioItemReturnee">
                          {{this.router.url.includes('EN')==true?'I have return in 2025': '2025年に帰国'}}
                        </mat-radio-button>
                        <mat-radio-button value='I have return in 2024' [checked]="user.ReturneeInfo=='I have return in 2024'"
                        class="radioItemReturnee">
                        {{this.router.url.includes('EN')==true?'I have return in 2024': '2024年に帰国'}}
                      </mat-radio-button>
                      <mat-radio-button value='I have return in 2023' [checked]="user.ReturneeInfo=='I have return in 2023'"
                      class="radioItemReturnee">
                      {{this.router.url.includes('EN')==true?'I have return in 2023': '2023年に帰国'}}
                    </mat-radio-button>
                        <mat-radio-button value='I have returned before 2020'
                          [checked]="user.ReturneeInfo=='I have returned before 2022'" class="radioItemReturnee">
                          {{this.router.url.includes('EN')==true?'I have returned before 2022': '2022年以前に帰国'}}
                        </mat-radio-button>
                        <mat-radio-button value='Residing in Japan as a foreigner'
                        [checked]="user.ReturneeInfo=='Residing in Japan as a foreigner'" class="radioItemReturnee">
                        {{this.router.url.includes('EN')==true?'Residing in Japan as a foreigner': '日本に外国人として在住'}}
                      </mat-radio-button><br>
                      <mat-radio-button value='No experience living abroad'
                      [checked]="user.ReturneeInfo=='No experience living abroad'" class="radioItemReturnee">
                      {{this.router.url.includes('EN')==true?'No experience living abroad': '海外滞在経験はありません'}}
                    </mat-radio-button>
                      </div>
                    </div>
                    <!-- <hr> -->
                    <div class="registrationInputFormLg">
                      <div class="regionGroupReturnee">
                        {{this.router.url.includes('EN')==true?'I live outside of Japan.: ': '海外在住です。：'}}
                      </div>
                      <div>
                        <mat-radio-button value='I will return in 2025'
                          [checked]="user.ReturneeInfo=='I will return in 2025'" class="radioItemReturnee">
                          {{this.router.url.includes('EN')==true?'I will return in 2025': '2025年に帰国予定'}}
                        </mat-radio-button>
                        <mat-radio-button value='I will return in 2026'
                          [checked]="user.ReturneeInfo=='I will return in 2026'" class="radioItemReturnee">
                          {{this.router.url.includes('EN')==true?'I will return in 2026': '2026年に帰国予定'}}
                        </mat-radio-button>
                        <mat-radio-button value='I will return after 2027'
                          [checked]="user.ReturneeInfo=='I will return after 2027'" class="radioItemReturnee">
                          {{this.router.url.includes('EN')==true?'I will return after 2027': '2027年以降に帰国予定'}}
                        </mat-radio-button>
                        <mat-radio-button value='Live permanently in a country other than Japan'
                        [checked]="user.ReturneeInfo=='Live permanently in a country other than Japan'" class="radioItemReturnee">
                        {{this.router.url.includes('EN')==true?'I will not return to Japan.': '永住予定です。'}}</mat-radio-button>
                      </div>
                    </div>
                    <!-- <div class="registrationInputFormLg">
                      <div class="regionGroupReturnee">
                        {{this.router.url.includes('EN')==true?'Other than above: ':
                        '上記以外：'}}
                      </div>
                      <mat-radio-button value='Permanent residence in a country other than Japan'
                        [checked]="user.ReturneeInfo=='Permanent residence in a country other than Japan'"
                        class="radioItemReturnee">
                        {{this.router.url.includes('EN')==true?'Permanent residence in a country other than Japan':
                        '日本以外の国に永住予定'}}
                      </mat-radio-button><br>
                      <mat-radio-button value='Residing in Japan as a foreigner'
                        [checked]="user.ReturneeInfo=='Residing in Japan as a foreigner'" class="radioItemReturnee">
                        {{this.router.url.includes('EN')==true?'Residing in Japan as a foreigner': '日本に外国人として在住'}}
                      </mat-radio-button><br>
                      <mat-radio-button
                        value='planning to immigrate or stay in Japan for a long period of time in the near future (those who need a visa to live in Japan) '
                        [checked]="user.ReturneeInfo=='planning to immigrate or stay in Japan for a long period of time in the near future (those who need a visa to live in Japan) '"
                        class="radioItemReturnee">
                        {{this.router.url.includes('EN')==true?'Planning to immigrate or stay in Japan for a long':
                        '近日中に移住・長期滞在予定 '}}<br>
                        {{this.router.url.includes('EN')==true?'period of time in the near future. ':
                        '（日本に住むのにビザが必要な人）'}}<br>
                        {{this.router.url.includes('EN')==true?'(those who need a visa to live in Japan) ':
                        ''}}
                      </mat-radio-button>
                      <div>
                        <mat-radio-button value='I live in Japan and never lived abroad'
                          [checked]="user.ReturneeInfo=='I live in Japan and never lived abroad'"
                          class="radioItemReturnee">
                          {{this.router.url.includes('EN')==true?'I live in Japan and never lived abroad.':
                          '海外滞在経験はありません'}}</mat-radio-button>
                      </div>
                    </div> -->
                  </div>
                  <mat-error
                    *ngIf="myFormGroup.get('myReturneeInfo').hasError('required')  && myFormGroup.get('myReturneeInfo').touched">
                    {{this.router.url.includes('EN')==true?'Not selected': '未入力です'}}
                  </mat-error>
                </mat-radio-group>
              </div>
            </div>
            <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext"
                  (click)="onClickGoForward()">
                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
              </div>
            </div>
            <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
