<div class="myBody myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
      <div class="myHeaderCore">
          <div>
              <!-- <img src='../../../../../assets/images/thumbnail_ec22logo.jpg'  class="ecLogo"> -->
          </div>
      </div>
  </div>
  <div class="myMain">
      <div clas="myMainLeft">
          <div class="myMainHalfTop">
              <!-- Left Top -->
          </div>
          <div class="myMainHalfBottom">
              <!-- Left Bottom -->
          </div>
      </div>
      <div class="myMainCenter">
          <div class="myMainHalfTop d-flex justify-content-center">
              <!-- Main Top -->

              <mat-card class="mainCard b-radius">
                  <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                  <div *ngIf="!loaded">
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </div>
                  <!-- <form *ngIf="userFromServer | async; else loading"  -->
                      <form
                      [formGroup]="myFormGroup"
                      (ngSubmit)="onSubmit()"
                      >
                      <div class="float-end stepNumber">2/8</div>
                      <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Volunteer Opportunities':
                          '奉仕'}}
                      </h2>
                      <div class="stepTitle2nd">
                        {{this.router.url.includes('EN')==true?'Please check the boxes for the services you wish to do. (Multiple selections are acceptable) A person in charge will contact you later.　':
                        '希望される奉仕にチェックを入れてください。（複数選択可）　後ほど担当者から連絡させていただきます。'}}
                    </div>

                    <!-- <div class="stepTitle2nd">
                      <div *ngIf="this.router.url.includes('EN')!=true">
                        {{'奉仕内容はホームページでご確認ください。'}}
                        <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=263&Itemid=823&lang=ja"
                          target="_blank">
                          {{ '奉仕内容' }}
                        </a>
                      </div>
                      <div *ngIf="this.router.url.includes('EN')==true">
                        {{'Please check the website for details of volunteers.'}}
                        <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=264:descriptions-of-volunteers&catid=19&lang=en&Itemid=151"
                          target="_blank">
                          {{ 'Descriptions of Volunteers' }}
                        </a>
                      </div>
                    </div> -->
                    <!-- <div class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?'For more information about the service, click ':
                      '奉仕についての詳細は'}}<a href="http://equipper.org/ec17/index.php?option=com_content&view=article&id=32:ecchart&catid=8&lang=ja&Itemid=168" target="_blank">
                        {{this.router.url.includes('EN')==true?'here': 'こちら'}}
                      </a>
                      {{this.router.url.includes('EN')==true?'(link will be updated to EC22).':
                      'を参照　（リンクはEC22にUpdateされます。）'}}
                  </div> -->
                      <div class="registrationInputFormLg">
                          <div>
                            <mat-checkbox value='Reception'  [checked]=receptionChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Registration table': '受付'}}
                            </mat-checkbox>
                            <mat-checkbox value='Sharing Testimony'
                            [checked]=testimoneyChecked
                              class="radioItemVolunteer"  (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Sharing Testimony': '証'}}
                            </mat-checkbox>
                            <mat-checkbox value='Skit' [checked]=skitChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)">
                              {{this.router.url.includes('EN')==true?'Skit(Acting)': 'スキット'}}
                            </mat-checkbox>
                            <mat-checkbox value='Bible Reading' [checked]=bibleReadingChecked class="radioItemVolunteer"
                              (change)="onCheckVolunteer($event)">
                              {{this.router.url.includes('EN')==true?'Scripture Reading': '聖書朗読'}}
                            </mat-checkbox>
                            <mat-checkbox value='Praise Team' [checked]=praiseTeamChecked class="radioItemVolunteer"
                            (change)="onCheckVolunteer($event)">
                            {{this.router.url.includes('EN')==true?'Worship team': '賛美チーム'}}
                          </mat-checkbox>
                              <mat-checkbox value='Usher' [checked]=usherChecked class="radioItemVolunteer"
                                (change)="onCheckVolunteer($event)">
                                {{this.router.url.includes('EN')==true?'Usher (offering)': '献金 アッシャー'}}
                              </mat-checkbox>
                              <mat-checkbox value='Propresenter' [checked]=propresenterChecked class="radioItemVolunteer"
                                (change)="onCheckVolunteer($event)">
                                {{this.router.url.includes('EN')==true?'Propresenter, PPT': '投影（Propresenter, PPT）'}}
                              </mat-checkbox>
                              <mat-checkbox value='Video/Photo' [checked]=videoPhotoChecked class="radioItemVolunteer"
                                (change)="onCheckVolunteer($event)">
                                {{this.router.url.includes('EN')==true?'Video/Photographer': 'ビデオ　写真撮影'}}
                              </mat-checkbox>
                              <mat-checkbox value='Video Editing' [checked]=videoEditingChecked class="radioItemVolunteer"
                                (change)="onCheckVolunteer($event)">
                                {{this.router.url.includes('EN')==true?'Video Editing': 'ビデオ編集'}}
                              </mat-checkbox>
                              <mat-checkbox value='Interpreter' [checked]=interpreterChecked class="radioItemVolunteer"
                                (change)="onCheckVolunteer($event)">
                                {{this.router.url.includes('EN')==true?'Interpreter': '通訳'}}
                              </mat-checkbox>
                              <mat-checkbox value='Translator' [checked]=translatorChecked class="radioItemVolunteer"
                              (change)="onCheckVolunteer($event)">
                              {{this.router.url.includes('EN')==true?'Translator(into English)': '翻訳 (英訳)'}}
                            </mat-checkbox>
                            <mat-checkbox value='EnglishAssistant' [checked]=englishAssistantChecked class="radioItemVolunteer"
                            (change)="onCheckVolunteer($event)">
                            {{this.router.url.includes('EN')==true?'English Assistant': '英語のアシスタント'}}
                          </mat-checkbox>
                            <mat-checkbox value='Workshop Helper'  [checked]=workshopHelperChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Workshop Helper': '分科会ヘルパー'}}
                            </mat-checkbox>
                            <mat-checkbox value='Facilitator of networking time'  [checked]=facilitatorOfNetworkingTimeChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Facilitator of networking time': 'ネットワーキングのファシリテーター'}}
                            </mat-checkbox>
                            <mat-checkbox value='Small Group Leader'  [checked]=smallGroupLeaderChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Small Group Leader': 'スモールグループリーダー '}}
                            </mat-checkbox>
                            <mat-checkbox value='Kids Program Helper'  [checked]=kidsProgramHelperChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Kids program Helper': '子どもプログラムヘルパー'}}
                            </mat-checkbox>
                            <mat-checkbox value='Child care helper'  [checked]=childCareHelperChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Child care helper': 'チャイルドケアヘルパー'}}
                            </mat-checkbox>
                            <mat-checkbox value='Nursing'  [checked]=nursingChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'camp doctor/Nurse': 'キャンプドクター／ナース'}}
                            </mat-checkbox>
                            <mat-checkbox value='Transportation'  [checked]=transportationChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Transportation': '車での送迎'}}
                            </mat-checkbox>
                            <mat-checkbox value='Conference site set-up'  [checked]=conferenceSiteSetUpChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Conference site set-up': '会場設営'}}
                            </mat-checkbox>
                            <mat-checkbox value='Booth set-up/management'  [checked]=boothSetUpManagementChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Booth set-up/management': 'ブース設営 管理'}}
                            </mat-checkbox>

                            <mat-checkbox value='Prayer' [checked]=prayerChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)">
                              {{this.router.url.includes('EN')==true?'Prayer': '祈り'}}
                            </mat-checkbox>




                           <!-- <mat-checkbox value='PA' [checked]=PAChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)">
                                {{this.router.url.includes('EN')==true?'PA': '音響'}}
                              </mat-checkbox>
                            <mat-checkbox value='Book Store'  [checked]=bookstoreChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Book Store': '本屋'}}
                            </mat-checkbox>
                            <mat-checkbox  value='Counselor'  [checked]=counselorChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)" >
                              {{this.router.url.includes('EN')==true?'Counselor': 'カウンセラー'}}
                            </mat-checkbox> -->

                          </div>
                        </div>
                      <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                          <div class="d-flex justify-content-center">
                              <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                              </button>
                              <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext"
                                  (click)="onClickGoForward()">
                                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                              </button>
                          </div>
                          <div class="d-flex justify-content-center">
                              <button type="button" class="btn link" (click)="cancelProcess()">
                                  {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                                </button>
                          </div>
                      </div>
                  </form>
              </mat-card>
          </div>
          <div class="myMainHalfBottom">
              <!-- Main Bottom -->
          </div>
      </div>
      <div class="myMainRight">
          <div class="myMainHalfTop">
              <!-- Right Top -->
          </div>
          <div class="myMainHalfBottom">
              <!-- Right Bottom -->
          </div>
      </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
